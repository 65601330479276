<ul
  class="layout-menu"
  [ngClass]="{ collapsed: sidebarExpanded }"
>
  <ng-container *ngFor="let item of model; let i = index">
    <li
      app-menuitem
      *ngIf="!item.separator && item.isVisible()"
      [item]="item"
      [index]="i"
      [root]="!!item.items"
      [displayLabel]="sidebarExpanded"
    ></li>
    <li
      *ngIf="item.separator"
      class="menu-separator"
    ></li>
  </ng-container>
</ul>

import { DynamicDialogRef } from 'primeng/dynamicdialog';

export class BaseDynamicDialog {
  keepOpen: boolean = true;
  saveDisabled: boolean = false;
  addMode: boolean = false;
  editMode: boolean = true;

  constructor(public ref: DynamicDialogRef) {}

  setKeepOpen(keepOpen: boolean): boolean {
    this.keepOpen = keepOpen;
    return true;
  }

  beginInit() {}

  close() {
    this.ref.close();
  }
}

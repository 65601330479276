import { Injectable } from '@angular/core';
import { ContexteUtilisateur } from '../models/contexte-utilisateur.model';
import { ProjetModel } from 'src/app/shared/models/ProjetModel';
import { EspaceModel } from 'src/app/shared/models/EspaceModel';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContexteUtilisateurService {
  private localStorageNom: string = 'ContexteUtilisateur';

  contexteUtilisateurSubject = new BehaviorSubject<ContexteUtilisateur | null>(
    null
  );

  private constructor() {}

  public set Contexte(contexte: ContexteUtilisateur) {
    const contexteJson = JSON.stringify(contexte);
    localStorage.setItem(this.localStorageNom, contexteJson);
    this.contexteUtilisateurSubject.next(contexte);
  }

  public ResetChantier() {
    const contexte =this.Contexte
    contexte.chantier=null;
    contexte.espace=null;
    const contexteJson = JSON.stringify(contexte);
    localStorage.setItem(this.localStorageNom, contexteJson);
    this.contexteUtilisateurSubject.next(contexte);
  }

  public get Contexte(): ContexteUtilisateur | null {
    const contexteJson = localStorage.getItem(this.localStorageNom);
    if (contexteJson) {
      return JSON.parse(contexteJson);
    }
    return null;
  }

  public get hasContexte(): boolean {
    if (this.Contexte) {

      return (
        !!this.Contexte.projet &&
        !!this.Contexte.chantier &&
        !!this.Contexte.espace
      );
    }
    return false;
  }

  public get Projet(): ProjetModel {
    return this.Contexte.projet;
  }

  public get Espace(): EspaceModel {
    return this.Contexte.espace;
  }

  public get hasProjet(): boolean {
    const contexte = this.Contexte;
    if (contexte) {
      return !!contexte.projet;
    }
    return false;
  }

  public get hasChantier(): boolean {
    const contexte = this.Contexte;
    if (contexte) {
      return !!contexte.chantier;
    }
    return false;
  }

  public get hasEspace(): boolean {
    const contexte = this.Contexte;
    if (contexte) {
      return !!contexte.espace;
    }
    return false;
  }
}

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserLoginService } from 'src/app/auth/services/user-login.service';
import { UtilisateurApiService } from '../api-services/utilisateur-api.service';
import { map, Observable, of } from 'rxjs';
import {
  NotificationsService,
  Severities,
} from '../services/notifications.service';

@Injectable({
  providedIn: 'root',
})
class CanActivateService {
  canActivate(
    userLoginService: UserLoginService,
    utilisateurApiService: UtilisateurApiService,
    notificationsService: NotificationsService,
    router: Router,
    next: ActivatedRouteSnapshot
  ): Observable<boolean> {
    if (!userLoginService.isLoggedIn) {
      router.navigate(['/account/do-login']);
      return of(false);
    }
    return utilisateurApiService.getUtilisateurInfo().pipe(
      map(() => {
        const droitElementaire = next.firstChild?.data[
          'droitElementaire'
        ] as Array<string>;
        if (droitElementaire) {
          const match =
            utilisateurApiService.hasDroitElementaires(droitElementaire);

          if (match) {
            return true;
          } else {
            return false;
          }
        }
        return this.checkDroitElementaires(
          utilisateurApiService,
          notificationsService,
          next
        );
      })
    );
  }

  checkDroitElementaires(
    utilisateurApiService: UtilisateurApiService,
    notificationsService: NotificationsService,
    next: ActivatedRouteSnapshot
  ): boolean {
    const droitElementaires = next.firstChild?.data[
      'droitElementaire'
    ] as Array<string>;

    if (droitElementaires) {
      const match =
        utilisateurApiService.hasDroitElementaires(droitElementaires);
      if (match) {
        return true;
      } else {
        notificationsService.notify(
          Severities.ERROR,
          'Erreur',
          'Zone non autorisée !'
        );
        return false;
      }
    }
    return true;
  }
}

export const canActivateRoute: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(CanActivateService).canActivate(
    inject(UserLoginService),
    inject(UtilisateurApiService),
    inject(NotificationsService),
    inject(Router),
    route
  );
};

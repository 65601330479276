import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules-libs/material.module';
import { CommonModule } from '@angular/common';
import { PrimengModule } from './modules-libs/primeng.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { RegexValidatorDirective } from './directives/regex-validator.directive';
import { SaisieChantierComponent } from './components/saisie-chantier/saisie-chantier.component';
import { SaisieEspaceComponent } from './components/saisie-espace/saisie-espace.component';
import { DurationPipe } from './pipes/duration.ipe';
import { ValueExistValidatorDirective } from './directives/value-exists.validator.directive';
import { HasDroitElementaireDirective } from './directives/has-droitElementaire.directive';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    RegexValidatorDirective,
    SaisieChantierComponent,
    SaisieEspaceComponent,
    DurationPipe,
    ValueExistValidatorDirective,
    HasDroitElementaireDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimengModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimengModule,
    SafeHtmlPipe,
    RegexValidatorDirective,
    SaisieChantierComponent,
    SaisieEspaceComponent,
    DurationPipe,
    ValueExistValidatorDirective,
    HasDroitElementaireDirective,
  ],
})
export class SharedModule {
  // constructor(iconRegistry: MatIconRegistry) {
  //   iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  // }
}

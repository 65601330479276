import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-unauthorized",
	templateUrl: "./app.unauthorized.component.html",
	styleUrls: ["./app.unauthorized.component.scss"],
})
export class AppUnauthorizedComponent {
	title: string = "usine2mig App";

	constructor() {
		this.title = environment.applicationTitle;
	}
}

import { createReducer, on } from "@ngrx/store";
import { setUser, clearUser } from "./user.actions";
import { UserState } from "./user.state";

export const initialState: UserState = {
  email: "",
  username: "",
  isLoggedIn: false,
  roles: [],
  groups: [],
  authorization: [],
};

export const userReducer = createReducer(
  initialState,
  on(setUser, (state, { email, username, roles, groups, isLoggedIn }) => ({
    ...state,
    email,
    username,
    roles,
    groups,
    isLoggedIn,
  })),
  on(clearUser, () => initialState),
);

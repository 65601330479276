import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-app.error",
	templateUrl: "./app.error.component.html",
	styleUrls: ["./app.error.component.css"],
})
export class AppErrorComponent {
	title: string = "usine2mig App";

	constructor() {
		this.title = environment.applicationTitle;
	}
}

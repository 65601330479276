<div
  class="overflow-y-auto max-h-60rem"
  *ngIf="espace$ | async as espace"
>
  <form
    #form="ngForm"
    (ngSubmit)="onSubmit(espace)"
    novalidate
  >
    <div class="card col-12 flex-wrap flex p-fluid justify-content-center">
      <div class="col-12 flex flex-column">
        <label for="label">Nom *</label>
        <input
          type="text"
          pInputText
          name="nom"
          id="nom"
          [(ngModel)]="espace.nom"
          valueExistValidator="name"
          context="espace"
          [projet]="code_projet"
          [chantier]="code_chantier"
          [oldValue]="espace_nom"
          required
          required
          autocomplete="off"
          maxlength="255"
          class="form-control"
          required
          #nom="ngModel"
        />
        <div
          *ngIf="nom.errors?.exists && nom.touched"
          class="text-red-600"
        >
          Le nom existe déjà.
        </div>
      </div>
      <div class="col-12 flex flex-column">
        <label for="name">Code *: </label>
        <input
          type="text"
          pInputText
          name="code"
          id="code"
          [(ngModel)]="espace.code_technique"
          required
          [appRegexValidator]="'^[a-zA-Z][a-zA-Z0-9_]*$'"
          valueExistValidator="code"
          context="espace"
          [projet]="code_projet"
          [chantier]="code_chantier"
          [disabled]="editMode"
          autocomplete="off"
          maxlength="255"
          class="form-control"
          #code="ngModel"
        />
        <div
          *ngIf="code.errors?.exists && code.touched"
          class="text-red-600"
        >
          Le code existe déjà.
        </div>
      </div>

      <div class="col-12 flex align-items-center">
        <p-checkbox
          [binary]="true"
          id="archive"
          name="archive"
          id="archive"
          [(ngModel)]="espace.est_verrouillee"
          inputId="binary"
        />
        <label for="name">Est verrouillée</label>
      </div>
      <div class="col-12 flex flex-column">
        <label for="description">Description *</label>
        <textarea
          pInputTextarea
          name="description"
          id="description"
          [(ngModel)]="espace.description"
          required
          rows="3"
          cols="20"
          class="form-control"
        ></textarea>
      </div>
      <div
        class="col-12 flex flex-column"
        *ngIf="environnements$ | async as environnements"
      >
        <label for="description">Environnement *</label>

        <p-dropdown
          [options]="environnements"
          name="environnement"
          id="environnement"
          [(ngModel)]="espace.environnement"
          [required]="true"
          optionLabel="nom"
          [showClear]="true"
          class="w-full"
          appendTo="body"
        />
      </div>
      <div class="col-12 flex flex-column">
        <label for="description">Paramètres</label>
        <p-table [value]="espace.parametres">
          <ng-template pTemplate="header">
            <tr>
              <th>Nom</th>
              <th>Valeur</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-parametre
          >
            <tr>
              <td style="width: 30%">
                {{ parametre.nom }}
                <ng-container *ngIf="parametre.est_obligatoire">
                  *</ng-container
                >
              </td>
              <td>
                <p-checkbox
                  *ngIf="parametre.type_parametre === typeParametreEnum.Booleen"
                  [binary]="true"
                  [id]="parametre.nom"
                  [name]="parametre.nom"
                  [(ngModel)]="parametre.valeurTem"
                  (ngModelChange)="onCheckboxChange(parametre)"
                  [required]="parametre.est_obligatoire"
                  [pTooltip]="parametre.nom"
                  inputId="binary"
                />

                <input
                  *ngIf="
                    parametre.type_parametre === typeParametreEnum.String128
                  "
                  type="text"
                  pInputText
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeur"
                  [required]="parametre.est_obligatoire"
                  [placeholder]="parametre.nom"
                  [pTooltip]="parametre.description"
                  tooltipPosition="left"
                  autocomplete="off"
                  maxlength="255"
                  class="form-control"
                />

                <input
                  *ngIf="
                    parametre.type_parametre === typeParametreEnum.StringCode
                  "
                  type="text"
                  pInputText
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeur"
                  [required]="parametre.est_obligatoire"
                  [placeholder]="parametre.nom"
                  [pTooltip]="parametre.description"
                  tooltipPosition="left"
                  autocomplete="off"
                  maxlength="255"
                  class="form-control"
                  [appRegexValidator]="'^[a-zA-Z][a-zA-Z0-9_]*$'"
                />
                <p-dropdown
                  *ngIf="
                    parametre.type_parametre === typeParametreEnum.StringChoix
                  "
                  [options]="parametre.liste_valeurs"
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeur"
                  [required]="parametre.est_obligatoire"
                  [placeholder]="parametre.nom"
                  [pTooltip]="parametre.description"
                  class="w-full"
                  appendTo="body"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="justify-content-center flex align-items-center gap-2">
      <p-button
        label="Annuler"
        (click)="close()"
        icon="pi pi-times"
        [raised]="true"
        pRipple
        severity="danger"
        [disabled]="loading"
      ></p-button>
      <p-button
        label="Enregister"
        type="submit"
        [disabled]="!form.form.valid || loading"
        icon="pi pi-check"
        [raised]="true"
        pRipple
        severity="secondary"
      ></p-button>
    </div>

    <div
      *ngIf="loading"
      class="overlay"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <p-progressSpinner></p-progressSpinner>
        <p class="w-7" *ngIf="editMode">
          La modifiation des répertoires Drive et l'initialisation des
          paramètres pour le nouveau contrat {{ code_chantier }} sont en cours.
          Cela peut prendre quelques secondes. Merci de patienter un instant.
        </p>
        <p class="w-7" *ngIf="!editMode">
          La création des répertoires Drive et l'initialisation des
          paramètres pour le nouveau contrat {{ code_chantier }} sont en cours.
          Cela peut prendre quelques secondes. Merci de patienter un instant.
        </p>
      </div>
    </div>
  </form>
</div>

import { NgModule } from '@angular/core';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { TreeSelectModule } from 'primeng/treeselect';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { DataViewModule } from 'primeng/dataview';
import { RatingModule } from 'primeng/rating';
import { ListboxModule } from 'primeng/listbox';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChipsModule } from 'primeng/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeTableModule } from 'primeng/treetable';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { PickListModule } from 'primeng/picklist';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';

import { ImageModule } from 'primeng/image';
import { FloatLabelModule } from 'primeng/floatlabel';

const SHARED_DEPENDENCIES = [
  ImageModule,
  DataViewModule,
  FloatLabelModule,
  TagModule,
  PickListModule,
  CardModule,
  DialogModule,
  PanelModule,
  ProgressSpinnerModule,
  AccordionModule,
  ProgressBarModule,
  TreeTableModule,
  TabViewModule,
  ChipsModule,
  ToolbarModule,
  MultiSelectModule,
  TableModule,
  TooltipModule,
  FieldsetModule,
  InputTextareaModule,
  InputTextModule,
  RadioButtonModule,
  ListboxModule,
  CheckboxModule,
  InputNumberModule,
  RatingModule,
  TreeModule,
  DropdownModule,
  TreeSelectModule,
  SidebarModule,
  DynamicDialogModule,
  ButtonModule,
  AvatarModule,
];

@NgModule({
  declarations: [],
  imports: [...SHARED_DEPENDENCIES],
  exports: [...SHARED_DEPENDENCIES],
  providers: [DialogService, ConfirmationService],
})
export class PrimengModule {}

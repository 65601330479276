import { Injectable } from '@angular/core';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { AuthProviderService } from './auth-provider.interface';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeycloakAuthService implements AuthProviderService {
  keycloak: any;

  constructor() {}

  public async init(options: any) {
    const { config, initOptions } = options;

    this.keycloak = new Keycloak(config);
    const authenticated = await this.keycloak.init(initOptions);

    return authenticated;
  }

  public login(redirectUri?: string): any {
    const options = {
      redirectUri,
    };

    this.keycloak.login(options);
  }

  public async logout(redirectUri?: string) {
    const options = {
      redirectUri,
    };

    await this.keycloak.logout(options);
  }

  public async getUserInfo(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile();
  }

  public get isAuthenticated(): boolean {
    return this.keycloak.authenticated;
  }

  public get getUserToken(): string {
    return this.keycloak.token;
  }

  public get isTokenExpired(): boolean {
    return this.keycloak.isTokenExpired(5);
  }

  public refreshToken(): Observable<any> {
    return from(
      this.keycloak.updateToken(30).then((refreshed) => {
       //if (refreshed) {
       //  console.log('Token was successfully refreshed');
       //} else {
       //  console.warn('Token is still valid');
       //}
        return this.keycloak.token;
      }).catch((error) => {
        console.error('Failed to refresh token', error);
        this.logout();
      })
    );
  }
}

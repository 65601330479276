import { Component, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DialogService,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ChantiersApiService } from 'src/app/core/api-services/chantiers-api.service';
import { map, Observable } from 'rxjs';
import { TypeParametreEnum } from 'src/app/shared/enums/TypeParametreEnum';
import { BaseDynamicDialog } from 'src/app/shared/models-front/BaseDynamicDialog';
import { SaisieChantierModel } from 'src/app/shared/models/ChantierModel';

@Component({
  templateUrl: './saisie-chantier.component.html',
})
export class SaisieChantierComponent
  extends BaseDynamicDialog
  implements OnInit
{
  chantier$: Observable<SaisieChantierModel>;
  code_projet: string;
  code_chantier: any;

  loading: boolean = false;

  typeParametreEnum = TypeParametreEnum;
  chantier_nom: string;

  constructor(
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public override ref: DynamicDialogRef,
    private chantiersApiService: ChantiersApiService
  ) {
    super(ref);
  }

  ngOnInit() {
    this.code_projet = this.config.data.code_projet;
    this.code_chantier = this.config.data.code_chantier;
    this.editMode = this.config.data.editMode;

    this.chantier$ = this.chantiersApiService
      .getChantier(this.code_projet, this.code_chantier)
      .pipe(
        map((chantier) => {
          this.chantier_nom = chantier.nom;
          chantier.parametres.forEach((parametre) => {
            if (parametre.type_parametre === this.typeParametreEnum.Booleen) {
              parametre.valeurTem = this.stringToBoolean(parametre.valeur);
            }
          });
          return chantier;
        })
      );
  }

  onSubmit(chantier: SaisieChantierModel) {
    this.loading = true;

    this.chantiersApiService
      .postChantier(this.code_projet, this.code_chantier, chantier)
      .subscribe(
        (data) => {
          this.loading = false;
          if (this.config.data.updateMethod) {
            this.config.data.updateMethod(data);
          }
          this.close();
        },
        () => {
          this.loading = false;
        }
      );
  }

  stringToBoolean(value: string): boolean {
    return value === 'TRUE';
  }

  booleanToString(value: boolean): string {
    return value ? 'TRUE' : 'FALSE';
  }

  onCheckboxChange(parametre: any) {
    parametre.valeur = this.booleanToString(parametre.valeurTem);
  }
}

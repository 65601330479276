import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AppLayoutModule } from '../layouts/app.layout.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorInterceptor } from './interceptors/httpError.interceptor';
import { GlobalErrorHandler } from './interceptors/global.errorHandler';
import { AuthModule } from '../auth/auth.module';
import { userReducer } from '../auth/store/user/user.reducer';
import { LoggerModule } from 'ngx-logger';
import { LoggerService } from './services/logger.service';
import { MessageService } from 'primeng/api';

registerLocaleData(localeFr);

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppLayoutModule,
    AuthModule,
    StoreModule.forRoot({ user: userReducer }),
    LoggerModule.forRoot(LoggerService.GetLoggerConfig()),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    DatePipe,
    MessageService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule a déja été chargé. Vous ne devez importer les modules Core que dans AppModule'
      );
    }
  }
}

import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UtilisateurApiService } from 'src/app/core/api-services/utilisateur-api.service';

@Directive({
  selector: '[appHasDroitElementaire]',
})
export class HasDroitElementaireDirective implements OnInit {
  @Input() appHasDroitElementaire: string[];

  constructor(
    private utilisateurApiService: UtilisateurApiService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    if (
      this.utilisateurApiService.hasDroitElementaires(
        this.appHasDroitElementaire
      ) ||
      this.utilisateurApiService.hasDroitElementaires(
        this.appHasDroitElementaire
      )
    ) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}

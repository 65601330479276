export enum DroitElementaireEnum {
  EXPLORATEUR_DONNEES = 'EXPLORATEUR_DONNEES',
  CONSULTATION_TABLEAU_BORD = 'CONSULTATION_TABLEAU_BORD',
  EXPORT_DONNEES = 'EXPORT_DONNEES',
  CONSULTATION_CONFIGURATION_CHANTIER = 'CONSULTATION_CONFIGURATION_CHANTIER',
  CONSULTATION_CONFIGURATION_CHANTIER_TECH = 'CONSULTATION_CONFIGURATION_CHANTIER_TECH',
  MODIFICATION_CONFIGURATION_CHANTIER = 'MODIFICATION_CONFIGURATION_CHANTIER',
  MODIFICATION_CONFIGURATION_CHANTIER_TECH = 'MODIFICATION_CONFIGURATION_CHANTIER_TECH',
  CONSULTATION_EXECUTION_CHAINE = 'CONSULTATION_EXECUTION_CHAINE',
  EXECUTION_CHAINE_NON_CRITIQUE = 'EXECUTION_CHAINE_NON_CRITIQUE',
  EXECUTION_CHAINE_CRITIQUE = 'EXECUTION_CHAINE_CRITIQUE',
  EXECUTION_CHAINE_CRITIQUE_ENV_INJECTION_CRITIQUE = 'EXECUTION_CHAINE_CRITIQUE_ENV_INJECTION_CRITIQUE',
  SIMULATION = 'SIMULATION',
}

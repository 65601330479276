<div
  class="layout-topbar"
  style="background-color: #0072bb"
>
  <a
    class="layout-topbar-logo"
    [routerLink]="'/'"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 173.9 51"
      style="enable-background: new 0 0 173.9 51"
      xml:space="preserve"
      class="ng-scope"
    >
      <g>
        <path
          style="fill: #ffffff"
          d="M90.5,0h-7H0c7.9,0,11.2,4.2,15.6,14.2C20,24.3,25.7,51,48,51h35.5h7h35.5c22.3,0,28-26.7,32.4-36.8C162.7,4.2,166,0,173.9,0H90.5z"
        ></path>
        <path
          style="display: none; fill: #ffffff"
          d="M8.8,14.2C13.2,24.3,18.9,51,41.2,51h77.5c22.3,0,28-26.7,32.4-36.8c4.4-10,7.7-14.2,15.6-14.2H-6.8C1.1,0,4.5,4.2,8.8,14.2z"
        ></path>
        <g>
          <path
            style="fill: #ec1c24"
            d="M55.6,30.6c1.3-1.4,2.5-3.3,2.2-4.9c-0.3-1.6-1.5-2.1-2.2-2.1c-0.8,0-2,0.5-2.2,2.1c-0.3,1.6,0.9,3.5,2.2,4.9c-4,0-7.2-3.3-7.2-7.3c0-4,3.2-7.3,7.3-7.3c4,0,7.3,3.2,7.3,7.3C62.8,27.3,59.6,30.6,55.6,30.6 M55.6,14.6c-5.6,0-10.2,4.6-10.2,10.2S50,35,55.6,35c5.6,0,10.2-4.6,10.2-10.2S61.2,14.6,55.6,14.6"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M97.2,21.8c-2,0-2.9,1.1-2.9,3.6c0,2.5,0.9,3.7,2.9,3.7c2,0,2.9-1.1,2.9-3.7C100.1,22.9,99.2,21.8,97.2,21.8M97.4,30.9c-1.5,0-3-0.4-3.9-1c-1.2-0.8-1.8-2.4-1.8-4.3c0-3.7,1.8-5.5,5.6-5.5c3.8,0,5.6,1.8,5.6,5.6C102.7,29.1,100.9,30.9,97.4,30.9"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M109.9,28.8c-1.5,0-2-0.1-2.4-0.4c-0.4-0.3-0.5-0.6-0.5-1.6v-5.4c0-0.5-0.5-1-1-1h-1.4v5.7c0,1.8,0.2,2.6,0.8,3.3c0.7,0.8,1.7,1.2,3.3,1.2h3.2v-1.8H109.9z"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M121.2,26.4l1.5-3.6l1.5,3.6H121.2z M124.1,20.4h-1.6c-0.6,0-1.2,0.4-1.4,0.9l-4,9.3h1.6c0.6,0,1.2-0.4,1.4-0.9l0.6-1.5h4.1l0.6,1.5c0.2,0.5,0.8,0.9,1.4,0.9h1.7L124.1,20.4z"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M114.5,20.4c-0.6,0-1,0.4-1,1v9.2h1.3c0.6,0,1-0.4,1-1v-9.2H114.5z"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M78.7,20.4c-0.6,0-1.2,0.4-1.4,0.9l-2.5,6.3l-2.7-6.3c-0.2-0.5-0.8-0.9-1.4-0.9h-1.6l4.5,10.2h1.3c0.6,0,1.1-0.4,1.4-0.9l4.2-9.3H78.7z"
          ></path>
          <path
            style="fill: #ec1c24"
            d="M81.1,25.6c0,1,0.2,3.7,2.7,4.7c0.6,0.2,1.2,0.3,2.1,0.3h4v-1.8h-3.1c-1.5,0-2.3-0.3-2.7-1.1c-0.2-0.3-0.2-0.7-0.3-1.2v-0.1h5.8v-1.8h-5.8v-0.1c0-0.5,0.1-0.9,0.3-1.2c0.4-0.8,1.2-1.1,2.7-1.1h3.1v-1.8h-4c-0.9,0-1.5,0.1-2.1,0.3c-2.4,1-2.7,3.7-2.7,4.7V25.6z"
          ></path>
        </g>
      </g>
    </svg>
  </a>
  <h4
    *ngIf="!projetEspace"
    class="text-50 font-medium m-0"
  >
    Usine de Migration
  </h4>
  <h4
    *ngIf="projetEspace"
    class="text-50 text-xl font-medium m-0"
  >
    {{ projetDescription }}
  </h4>

  <div class="layout-topbar-menu">
    <div
      class="flex flex-column text-white"
      *ngIf="projetEspace"
    >
      <label
        for="utilisateur"
        *ngIf="!hasEmailSimulatedUser"
        >Se connecter en tant que</label
      >
      <label
        for="utilisateur"
        *ngIf="hasEmailSimulatedUser"
        >Connecté en tant que</label
      >
      <div class="p-inputgroup">
        <input
          type="text"
          pInputText
          [readOnly]="hasEmailSimulatedUser"
          [(ngModel)]="emailSimulatedUser"
          placeholder="Se connecter en tant que"
          (keyup.enter)="simulate()"
        />
        <span class="p-inputgroup-addon">
          <i
            class="pi pi-times cursor-pointer"
            *ngIf="emailSimulatedUser && hasEmailSimulatedUser"
            (click)="exitModeSimulation()"
          ></i>
          <i
            class="pi pi-check cursor-pointer"
            *ngIf="emailSimulatedUser && !hasEmailSimulatedUser"
            (click)="simulate()"
          ></i>
        </span>
      </div>
    </div>
    <button
      pButton
      *ngIf="projetEspace"
      pRipple
      type="button"
      [label]="projetEspace"
      class="p-button-info p-button-text text-50 text-xl"
      (click)="openConfigurationChantier()"
    ></button>
  </div>
</div>

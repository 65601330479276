import { createAction, props } from '@ngrx/store';

export const setUser = createAction(
  '[User] Set User',
  props<{
    email: string;
    username: string;
    roles: Array<any>;
    groups: Array<any>;
    isLoggedIn: boolean;
    authorization: any;
  }>()
);

export const setUserLoggedInState = createAction(
  '[User] Set setUserLoggedInState',
  props<{ isLoggedIn: boolean }>()
);

export const clearUser = createAction('[User] Clear User');

import { Component } from "@angular/core";

import { environment } from "src/environments/environment";
import * as firebase from "firebase/app";
import {PrimeNGConfig} from "primeng/api";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	title = "usine2mig";

	constructor(private config: PrimeNGConfig) {
		this.title = environment.applicationTitle;

		if (firebase.getApps().length === 0) {
			const config = {
				apiKey: environment.apiKey,
				authDomain: "fr-ist-isteau-ddesk-finap-rec.firebaseapp.com",
			};
			firebase.initializeApp(config);
		}

		this.config.setTranslation({ //traduction pour les composant prime-ng
		  startsWith: 'Commence par',
		  contains: 'Contient',
		  notContains: 'Ne contient pas',
		  endsWith: 'Finit par',
		  equals: 'Égal à',
		  notEquals: 'Différent de',
		  noFilter: 'Pas de filtre',
		  lt: 'Moins que',
		  lte: 'Moins ou égal à',
		  gt: 'Plus que',
		  gte: 'Plus ou égal à',
		  dateIs: 'La date est',
		  dateIsNot: 'La date n\'est pas',
		  dateBefore: 'Date avant',
		  dateAfter: 'Date après',
		  clear: 'Effacer',
		  apply: 'Appliquer',
		  matchAll: 'Correspond à tout',
		  matchAny: 'Correspond à un',
		  addRule: 'Ajouter une règle',
		  removeRule: 'Supprimer la règle',
		  // Ajoutez d'autres traductions au besoin
		});
	}
}

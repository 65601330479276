import { Injectable } from '@angular/core';
import { UtilisateurModel } from 'src/app/shared/models/habilitationModel';
import { HabilitationApiService } from './habilitation-api.service';
import { map, Observable, of } from 'rxjs';
import { ContexteUtilisateurService } from '../services/contexte-utilisateur.service';

@Injectable({ providedIn: 'root' })
export class UtilisateurApiService {
  currentUser: UtilisateurModel = null;
  code_projet: string;

  constructor(
    private habilitationService: HabilitationApiService,
    private contexteUtilisateurService: ContexteUtilisateurService
  ) {
    this.code_projet = this.contexteUtilisateurService.Contexte?.projet.nom;
  }

  getUtilisateurInfo(recharger: boolean = false): Observable<UtilisateurModel> {
    if (!recharger && this.currentUser) {
      return of(this.currentUser);
    }
    return this.habilitationService.getUserHabiliation().pipe(
      map((u) => {
        this.currentUser = u;
        return u;
      })
    );
  }

  getSimulateUser(
    email: string,
    codeProjet: string
  ): Observable<UtilisateurModel> {
    return this.habilitationService
      .getSimulatedUserHabiliation(email, codeProjet)
      .pipe(
        map((u) => {
          this.currentUser = u;
          return u;
        })
      );
  }

  exitSimulateUser(codeProjet: string): Observable<UtilisateurModel> {
    return this.habilitationService
      .exitSimulatedUserHabiliation(codeProjet)
      .pipe(
        map((u) => {
          this.currentUser = u;
          return u;
        })
      );
  }

  hasDroitElementaires(allowedDroitElementaires: string[]): boolean {
    if (!this.code_projet) return false;

    const projet = this.currentUser?.projets?.find(
      (p) => p.nom === this.code_projet
    );
    if (!projet) return false;

    return (
      projet?.droits_elementaires?.some(
        (p) => allowedDroitElementaires.indexOf(p) >= 0
      ) || false
    );
  }
  //est_autorise(
  //  nom_projet: string,
  //  nom_espace: string,
  //  droit_elementaire: string
  //) {
  //  if (this._utilisateur.value.projets) {
  //    for (let projet of this._utilisateur.value.projets) {
  //      if (nom_projet === projet.nom) {
  //        for (let espace of projet.espaces) {
  //          if (nom_espace === espace) {
  //            for (let de of projet.droits_elementaires) {
  //              if (droit_elementaire === de) {
  //                return true;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //  return false;
  //}
}

import { Directive, Input } from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, delay } from 'rxjs/operators';
import { ChantiersApiService } from 'src/app/core/api-services/chantiers-api.service';
import { EspacesApiService } from 'src/app/core/api-services/espaces-api.service';

@Directive({
  selector: '[valueExistValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ValueExistValidatorDirective,
      multi: true,
    },
  ],
})
export class ValueExistValidatorDirective implements AsyncValidator {
  @Input('valueExistValidator') type: 'name' | 'code' = 'name';
  @Input() context: 'chantier' | 'espace';
  @Input() projet: string;
  @Input() chantier: string | null;
  @Input() oldValue: string | null;

  constructor(
    private chantierService: ChantiersApiService,
    private espaceService: EspacesApiService
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {

    if (!control.value || this.oldValue === control.value) {
      return of(null);
    }

    return of(control.value).pipe(
      delay(500),
      switchMap((value) => {
        if (this.context === 'chantier') {
          return this.type === 'name'
            ? this.chantierService.checkNom(this.projet, value)
            : this.chantierService.checkCode(this.projet, value);
        } else {
          return this.type === 'name'
            ? this.espaceService.checkNom(this.projet, this.chantier, value)
            : this.espaceService.checkCode(this.projet, this.chantier, value);
        }
      }),
      map((exists) => {
        return exists ? { exists: true } : null;
      }),
      catchError(() => of(null))
    );
  }
}

import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { UserLoginService } from "./services/user-login.service";
import { Observable } from "rxjs";

export function initializeApp(authService: UserLoginService) {
	return (): Observable<any> => {
		return authService.init();
	};
}
@NgModule({
	declarations: [],
	imports: [],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			multi: true,
			deps: [UserLoginService],
		},

	],
})
export class AuthModule {
	constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
		if (parentModule) {
			throw new Error(
				"AuthModuleKeycloakModule a déja été chargé. Vous ne devez importer les modules Core que dans AppModule"
			);
		}
	}
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  EspaceModel,
  SaisieEspaceModel,
} from 'src/app/shared/models/EspaceModel';
import { SaisieChantierModel } from 'src/app/shared/models/ChantierModel';

@Injectable({ providedIn: 'root' })
export class EspacesApiService {
  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {}

  public getEspace(
    nom_projet: string,
    code_chantier: string,
    nom_espace: string = null
  ): Observable<SaisieEspaceModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);
    if (nom_espace) params = params.append('espace', nom_espace);

    return this.http
      .get<any>(`${this.apiUrl}espace/saisir`, {
        params: params,
      })
      .pipe<SaisieEspaceModel>(map((result) => result.reponse));
  }

  public getConfigurationEspace(
    nom_projet: string,
    code_chantier: string,
    nom_espace: string
  ): Observable<SaisieEspaceModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);
    if (nom_espace) params = params.append('espace', nom_espace);

    return this.http
      .get<any>(`${this.apiUrl}espace/configuration`, {
        params: params,
      })
      .pipe<SaisieEspaceModel>(map((result) => result.reponse));
  }

  public postEspace(
    nom_projet: string,
    code_chantier: string,
    nom_espace: string = null,
    espace: SaisieEspaceModel
  ): Observable<EspaceModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);
    if (nom_espace) params = params.append('espace', nom_espace);

    return this.http
      .post<any>(`${this.apiUrl}espace/saisir`, espace, {
        params: params,
      })
      .pipe<EspaceModel>(map((result) => result.reponse));
  }

  public checkCode(
    nom_projet: string,
    code_chantier: string,
    code_espace: string
  ): Observable<boolean> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);
    params = params.append('espace', code_espace);

    return this.http
      .get<any>(`${this.apiUrl}espace/checkCode`, {
        params: params,
      })
      .pipe<boolean>(map((result) => result.reponse));
  }

  public checkNom(
    nom_projet: string,
    code_chantier: string,
    nom_espace: string
  ): Observable<boolean> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);
    params = params.append('espace', nom_espace);

    return this.http
      .get<any>(`${this.apiUrl}espace/checkNom`, {
        params: params,
      })
      .pipe<boolean>(map((result) => result.reponse));
  }
}

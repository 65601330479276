import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appRegexValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RegexValidatorDirective,
      multi: true,
    },
  ],
})
export class RegexValidatorDirective implements Validator {
  @Input('appRegexValidator') regex: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    // Si pas de regex définie ou pas de valeur, on considère que c'est valide
    if (!this.regex || !control.value) {
      return null;
    }

    const pattern = new RegExp(this.regex);
    const valid = pattern.test(control.value);
    return valid ? null : { appRegexValidator: { value: control.value } };
  }
}

import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";

export enum Severities {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private messageService: MessageService) {}

  notify(
    severity: Severities,
    summary: string,
    detail: string = '',
    life: number = 5000,
  ) {
    this.showToast(severity, summary, detail, life);
  }

  private showToast(severity: Severities, summary: string, detail: string, life: number) {
    this.messageService.add({ severity, summary, detail, life: life });
  }
}

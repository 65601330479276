<div class="sidebar-header flex justify-content-end">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-bars"
    (click)="onToggleSidebar()"
    class="p-button-text mb-3"
  ></button>
</div>
<app-menu [sidebarExpanded]="sidebarExpanded"></app-menu>

<div
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 60vh"
>
  <mat-card>
    <mat-card-content>
      <div class="w-30rem">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="align-items-center"
          class="align-items-center mb-4"
        >
          <img
            class="w-10rem"
            src="assets/img/logo-veolia.png"
          />
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign=" center"
        >
          <mat-icon
            style="
              height: 50px;
              width: 50px;
              font-size: 40px;
              margin-right: 10px;
            "
            >warning</mat-icon
          >
          <h2 style="padding-top: 12px">401 Autorisation requise...</h2>
        </div>
        <h3 class="w-full mt-3">Erreur d'authentification.</h3>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <a
            mat-flat-button
            color="primary"
            [routerLink]="['/']"
            >Retour à la page d'acceuil</a
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

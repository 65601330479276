import { Component, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DialogService,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { map, Observable } from 'rxjs';
import { EspacesApiService } from 'src/app/core/api-services/espaces-api.service';
import { ProjetApiService } from 'src/app/core/api-services/projets-api.service';
import { TypeParametreEnum } from 'src/app/shared/enums/TypeParametreEnum';
import { BaseDynamicDialog } from 'src/app/shared/models-front/BaseDynamicDialog';
import { EnvironnementModel } from 'src/app/shared/models/EnvironnementModel';
import { SaisieEspaceModel } from 'src/app/shared/models/EspaceModel';

@Component({
  templateUrl: './saisie-espace.component.html',
})
export class SaisieEspaceComponent extends BaseDynamicDialog implements OnInit {
  espace$: Observable<SaisieEspaceModel>;
  environnements$: Observable<EnvironnementModel[]>;

  code_chantier: any;
  code_projet: any;
  code_espace: any;

  typeParametreEnum = TypeParametreEnum;

  loading: boolean = false;
  espace_nom: string;

  constructor(
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public override ref: DynamicDialogRef,
    private espacesApiService: EspacesApiService,
    private projetApiService: ProjetApiService
  ) {
    super(ref);
  }

  ngOnInit() {
    this.code_projet = this.config.data.code_projet;
    this.code_chantier = this.config.data.code_chantier;
    this.code_espace = this.config.data.code_espace;
    this.editMode = this.config.data.editMode;

    this.espace$ = this.espacesApiService
      .getEspace(this.code_projet, this.code_chantier, this.code_espace)
      .pipe(
        map((espace) => {
          this.espace_nom = espace.nom;
          espace.environnement = espace.environnement.code
            ? espace.environnement
            : null;
          espace.parametres.forEach((parametre) => {
            if (parametre.type_parametre === this.typeParametreEnum.Booleen) {
              parametre.valeurTem = this.stringToBoolean(parametre.valeur);
            }
          });
          return espace;
        })
      );
    this.environnements$ = this.projetApiService.getEnvironnements(
      this.code_projet
    );
  }

  onSubmit(espace: SaisieEspaceModel) {
    this.loading = true;
    this.espacesApiService
      .postEspace(
        this.code_projet,
        this.code_chantier,
        this.code_espace,
        espace
      )
      .subscribe(
        (data) => {
          this.loading = false;
          if (this.config.data.updateMethod) {
            this.config.data.updateMethod(data);
          }
          this.close();
        },
        () => {
          this.loading = false;
        }
      );
  }

  // Méthode pour convertir la valeur string en boolean pour p-checkbox
  stringToBoolean(value: string): boolean {
    return value === 'TRUE';
  }

  // Méthode pour convertir la valeur boolean en string
  booleanToString(value: boolean): string {
    return value ? 'TRUE' : 'FALSE';
  }

  // Méthode appelée lorsque la valeur change
  onCheckboxChange(parametre: any) {
    parametre.valeur = this.booleanToString(parametre.valeurTem);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContexteUtilisateurService } from 'src/app/core/services/contexte-utilisateur.service';
import {NotificationsService, Severities} from "../../core/services/notifications.service";

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
  styleUrls: ['./app.main.component.scss'],
})
export class AppMainComponent implements OnInit {
  sidebarExpanded: boolean = true;

  constructor(
    private contexteUtilisateurService: ContexteUtilisateurService,
    private router: Router,
  ) {
    if (!this.contexteUtilisateurService.hasContexte) {
      if (this.contexteUtilisateurService.hasProjet) {
        this.router.navigate(['/configuration/selectionChantier']);
      }else{
        this.router.navigate(['/configuration/selectionProjet']);
      }
    }


  }

  ngOnInit() {
    const savedState = localStorage.getItem('sidebarExpanded');
    if (savedState !== null) {
      this.sidebarExpanded = JSON.parse(savedState);
    }
  }

  toggleSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
    localStorage.setItem(
      'sidebarExpanded',
      JSON.stringify(this.sidebarExpanded)
    );
  }
}

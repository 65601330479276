import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  ChantierModel,
  SaisieChantierModel,
} from 'src/app/shared/models/ChantierModel';

@Injectable({ providedIn: 'root' })
export class ChantiersApiService {
  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {}

  public getChantiers(
    nom_projet: string,
    afficherArchivee: boolean
  ): Observable<ChantierModel[]> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('projet', nom_projet);
    queryParams = queryParams.append('archivee', afficherArchivee);
    return this.http
      .get<any>(`${this.apiUrl}chantiers`, { params: queryParams })
      .pipe<ChantierModel[]>(map((result) => result.reponse));
  }

  public getChantier(
    nom_projet: string,
    nom_chantier: string = null
  ): Observable<SaisieChantierModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    if (nom_chantier) params = params.append('chantier', nom_chantier);

    return this.http
      .get<any>(`${this.apiUrl}chantier/saisir`, {
        params: params,
      })
      .pipe<SaisieChantierModel>(map((result) => result.reponse));
  }

  public getConfigurationChantier(
    nom_projet: string,
    nom_chantier: string
  ): Observable<SaisieChantierModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    if (nom_chantier) params = params.append('chantier', nom_chantier);

    return this.http
      .get<any>(`${this.apiUrl}chantier/configuration`, {
        params: params,
      })
      .pipe<SaisieChantierModel>(map((result) => result.reponse));
  }

  public postChantier(
    nom_projet: string,
    nom_chantier: string = null,
    chantierModel: SaisieChantierModel
  ): Observable<boolean> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    if (nom_chantier) params = params.append('chantier', nom_chantier);

    return this.http
      .post<any>(`${this.apiUrl}chantier/saisir`, chantierModel, {
        params: params,
      })
      .pipe<boolean>(map((result) => result.reponse));
  }

  public checkNom(
    nom_projet: string,
    nom_chantier: string
  ): Observable<boolean> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', nom_chantier);

    return this.http
      .get<any>(`${this.apiUrl}chantier/checkNom`, {
        params: params,
      })
      .pipe<boolean>(map((result) => result.reponse));
  }

  public checkCode(
    nom_projet: string,
    code_chantier: string
  ): Observable<boolean> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('chantier', code_chantier);

    return this.http
      .get<any>(`${this.apiUrl}chantier/checkCode`, {
        params: params,
      })
      .pipe<boolean>(map((result) => result.reponse));
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';

import { setUser } from '../store/user/user.actions';
import { UserState } from '../store/user/user.state';
import { AuthProviderService } from './auth-provider.interface';

@Injectable({ providedIn: 'root' })
export class UserLoginService {
  private authProvider: AuthProviderService;

  constructor(
    private injector: Injector,
    private store: Store<{ user: UserState }>
  ) {
    this.authProvider = injector.get(environment.auth.provider);
  }

  init(): Observable<any> {
    return from(this.authProvider.init(environment.auth.config)).pipe(
      map((authenticated) => {
        if (authenticated) {
          this.onLoginSuccess();
        }
      })
    );
  }

  public login() {
    this.authProvider.login(environment.auth.loginRedirectUri);
  }

  public logout(): Observable<void> {
    return from(this.authProvider.logout(environment.auth.logoutRedirectUri));
  }

  public get isLoggedIn(): boolean {
    return this.authProvider.isAuthenticated;
  }

  private onLoginSuccess = async () => {
    from(this.authProvider.getUserInfo()).subscribe((profile) => {
      const userData = {
        ...profile,
        isLoggedIn: true,
        username: `${profile.firstName} ${profile.lastName}`,
      };
      this.store.dispatch(setUser(userData));
    });
  };
}

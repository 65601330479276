<div class="layout-wrapper">
  <app-header></app-header>
  <p-toast></p-toast>
  <div
    class="layout-sidebar"
    [ngClass]="{ collapsed: !sidebarExpanded }"
  >
    <app-sidebar
      [sidebarExpanded]="sidebarExpanded"
      (toggleSidebar)="toggleSidebar()"
    ></app-sidebar>
  </div>
  <div
    class="layout-main-container"
    [ngClass]="{ 'sidebar-expanded': sidebarExpanded }"
  >
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, from } from 'rxjs';
import {
  ProjetLightModel,
  ProjetModel,
} from 'src/app/shared/models/ProjetModel';
import { EnvironnementModel } from 'src/app/shared/models/EnvironnementModel';

@Injectable({ providedIn: 'root' })
export class ProjetApiService {
  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {}

  public getProjet(
    nom_projet: string,
    nom_espace: string
  ): Observable<ProjetModel> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    params = params.append('espace', nom_espace);

    return this.http
      .get<any>(`${this.apiUrl}projet`, {
        params: params,
      })
      .pipe<ProjetModel>(map((result) => result.reponse));
  }

  public getProjets(queryParams?: any): Observable<ProjetLightModel[]> {
    return this.http
      .get<any>(
        `${this.apiUrl}projets`,
        typeof queryParams !== 'undefined' ? { params: queryParams } : {}
      )
      .pipe<ProjetLightModel[]>(map((result) => result.reponse));
  }

  public getEnvironnements(
    nom_projet: string
  ): Observable<EnvironnementModel[]> {
    let params = new HttpParams();

    params = params.append('projet', nom_projet);
    return this.http
      .get<any>(`${this.apiUrl}projet/environnements`, { params: params })
      .pipe<EnvironnementModel[]>(map((result) => result.reponse));
  }
}

import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { UtilisateurApiService } from 'src/app/core/api-services/utilisateur-api.service';
import { ContexteUtilisateurService } from 'src/app/core/services/contexte-utilisateur.service';
import { DroitElementaireEnum } from 'src/app/shared/enums/DroitElementaireEnum';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  @Input() sidebarExpanded: boolean = true;

  model: any[] = [];
  hasContexte: () => boolean;
  hasProjet: () => boolean;

  constructor(
    public contexteUtilisateurService: ContexteUtilisateurService,
    private utilisateurApiService: UtilisateurApiService
  ) {}

  ngOnInit() {
    this.hasContexte = () => this.contexteUtilisateurService.hasContexte;
    this.hasProjet = () => this.contexteUtilisateurService.hasProjet;
    this.contexteUtilisateurService.contexteUtilisateurSubject.subscribe(
      (_) => {
        this.initModelMenu();
      }
    );
  }

  initModelMenu() {
    this.model = [
      {
        label: 'Sélection Projet',
        icon: 'pi pi-fw pi-bullseye',
        routerLink: ['/configuration/selectionProjet'],
        isVisible: () => true,
      },
      {
        label: 'Sélection Chantier',
        icon: 'pi pi-fw pi-folder',
        routerLink: ['/configuration/selectionChantier'],
        isVisible: () => this.hasProjet(),
      },
      {
        label: 'Exécution',
        isVisible: () =>
          this.hasContexte() &&
          this.utilisateurApiService.hasDroitElementaires([
            DroitElementaireEnum.CONSULTATION_CONFIGURATION_CHANTIER,
            DroitElementaireEnum.CONSULTATION_EXECUTION_CHAINE,
          ]),
        items: [
          {
            label: 'Configuration chantier',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/execution/configurationChantier'],
            isVisible: () =>
              this.hasContexte() &&
              this.utilisateurApiService.hasDroitElementaires([
                DroitElementaireEnum.CONSULTATION_CONFIGURATION_CHANTIER,
              ]),
          },
          {
            label: 'Chaînes',
            icon: 'pi pi-fw pi-play-circle',
            routerLink: ['/execution/chaines'],
            isVisible: () =>
              this.hasContexte() &&
              this.utilisateurApiService.hasDroitElementaires([
                DroitElementaireEnum.CONSULTATION_EXECUTION_CHAINE,
              ]),
          },
        ],
      },
      {
        label: 'Synthèse traitement',
        isVisible: () =>
          this.hasContexte() &&
          this.utilisateurApiService.hasDroitElementaires([
            DroitElementaireEnum.CONSULTATION_TABLEAU_BORD,
          ]),
        items: [
          {
            label: 'Compteurs lignes',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/synthese/ligne-exclues'],
            isVisible: () => true,
          },

          {
            label: "Compteurs indicateurs",
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/synthese/indicateurs'],
            isVisible: () => true,
          },

          {
            label: "Synthèse comptage",
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/synthese/comptage'],
            isVisible: () =>
              this.hasContexte() &&
              this.utilisateurApiService.hasDroitElementaires([
                DroitElementaireEnum.CONSULTATION_SYNTH_CPTAGE,
              ]),
          },
        ],
      },
      {
        label: 'Exploration de donnnées',
        isVisible: () =>
          this.hasContexte() &&
          this.utilisateurApiService.hasDroitElementaires([
            DroitElementaireEnum.EXPLORATEUR_DONNEES,
          ]),
        items: [
          {
            label: 'Par indicateur',
            icon: 'pi pi-fw pi-gauge',
            routerLink: ['/exploration/indicateur'],
            isVisible: () => true,
          },
          {
            label: 'Par entités',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/exploration/entite'],
            isVisible: () => true,
          },
          {
            label: 'Recherche',
            icon: 'pi pi-fw pi-search',
            routerLink: ['/exploration/recherche'],
            isVisible: () => true,
          },
        ],
      },
    ];
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) {}

  handleError(error: any): void {
    this.loggerService.logger.error(error.stack);
    console.log(error);
  }
}

<div
  class="overflow-y-auto max-h-60rem"
  *ngIf="chantier$ | async as chantier"
>
  <form
    #form="ngForm"
    (ngSubmit)="onSubmit(chantier)"
    novalidate
  >
    <div class="card col-12 flex-wrap flex p-fluid justify-content-center">
      <div class="col-12 flex flex-column">
        <label for="label">Nom *</label>
        <input
          type="text"
          pInputText
          name="nom"
          id="nom"
          [(ngModel)]="chantier.nom"
          valueExistValidator="name"
          context="chantier"
          [projet]="code_projet"
          [oldValue]="chantier_nom"
          required
          autocomplete="off"
          maxlength="255"
          class="form-control"
          required
          #nom="ngModel"
        />
        <div
          *ngIf="nom.errors?.exists && nom.touched"
          class="text-red-600"
        >
          Le nom existe déjà.
        </div>
      </div>
      <div class="col-12 flex flex-column">
        <label for="name">Code *: </label>
        <input
          type="text"
          pInputText
          name="code"
          id="code"
          [(ngModel)]="chantier.code_technique"
          [disabled]="editMode"
          valueExistValidator="code"
          context="chantier"
          [projet]="code_projet"
          [appRegexValidator]="'^[a-zA-Z][a-zA-Z0-9_]*$'"
          required
          autocomplete="off"
          maxlength="255"
          class="form-control"
          #code="ngModel"
        />
        <div
          *ngIf="code.errors?.exists && code.touched"
          class="text-red-600"
        >
          Le code existe déjà.
        </div>
      </div>

      <div class="col-12 flex align-items-center">
        <p-checkbox
          [binary]="true"
          id="archive"
          name="archive"
          id="archive"
          [(ngModel)]="chantier.est_archivee"
          inputId="binary"
        />
        <label for="name">Est archivé</label>
      </div>
      <div class="col-12 flex flex-column">
        <label for="description">Description *</label>
        <textarea
          pInputTextarea
          name="description"
          id="description"
          [(ngModel)]="chantier.description"
          required
          rows="3"
          cols="20"
          class="form-control"
        ></textarea>
      </div>
      <div class="col-12 flex flex-column">
        <label for="description">Paramètres</label>
        <p-table [value]="chantier.parametres">
          <ng-template pTemplate="header">
            <tr>
              <th>Nom</th>
              <th>Valeur</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-parametre
          >
            <tr>
              <td style="width: 30%">
                {{ parametre.nom }}
                <ng-container *ngIf="parametre.est_obligatoire">
                  *</ng-container
                >
              </td>
              <td>
                <p-checkbox
                  *ngIf="parametre.type_parametre === typeParametreEnum.Booleen"
                  [binary]="true"
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeurTem"
                  (ngModelChange)="onCheckboxChange(parametre)"
                  [required]="parametre.est_obligatoire"
                  [pTooltip]="parametre.nom"
                  inputId="binary"
                />

                <input
                  *ngIf="
                    parametre.type_parametre === typeParametreEnum.String128
                  "
                  type="text"
                  pInputText
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeur"
                  [required]="parametre.est_obligatoire"
                  [placeholder]="parametre.nom"
                  [pTooltip]="parametre.description"
                  tooltipPosition="left"
                  autocomplete="off"
                  maxlength="255"
                  class="form-control"
                />

                <p-dropdown
                  *ngIf="
                    parametre.type_parametre === typeParametreEnum.StringChoix
                  "
                  [options]="parametre.liste_valeurs"
                  [name]="parametre.nom"
                  [id]="parametre.nom"
                  [(ngModel)]="parametre.valeur"
                  [required]="parametre.est_obligatoire"
                  [placeholder]="parametre.nom"
                  [pTooltip]="parametre.description"
                  class="w-full"
                  appendTo="body"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="justify-content-center flex align-items-center gap-2">
      <p-button
        label="Annuler"
        (click)="this.close()"
        icon="pi pi-times"
        [raised]="true"
        pRipple
        severity="danger"
        pRipple
      ></p-button>

      <p-button
        label="Enregister"
        type="submit"
        [disabled]="!form.form.valid"
        (click)="setKeepOpen(false)"
        icon="pi pi-check"
        [raised]="true"
        pRipple
        severity="secondary"
      ></p-button>
    </div>
    <div
      *ngIf="loading"
      class="overlay"
    >
      <p-progressSpinner></p-progressSpinner>
    </div>
  </form>
</div>

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from './layouts/app.main/app.main.component';
import { AppUnauthorizedComponent } from './layouts/app.unauthorized/app.unauthorized.component';
import { AppNotfoundComponent } from './layouts/app.notfound/app.notfound.component';
import { AppErrorComponent } from './layouts/app.error/app.error.component';
import { canActivateRoute } from './core/guards/canActivate.guard';
import { DroitElementaireEnum } from './shared/enums/DroitElementaireEnum';

const routes: Routes = [
  {
    path: '',
    component: AppMainComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [canActivateRoute],
    children: [
      {
        path: '',
        redirectTo: 'configuration',
        pathMatch: 'full',
      },
      {
        path: 'exploration',
        canActivate: [canActivateRoute],
        data: {
          droitElementaire: [DroitElementaireEnum.EXPLORATEUR_DONNEES],
        },
        loadChildren: () =>
          import('./modules/exploration/exploration.module').then(
            (x) => x.ExplorationModule
          ),
      },
      {
        path: 'synthese',
        canActivate: [canActivateRoute],
        data: {
          droitElementaire: [DroitElementaireEnum.CONSULTATION_TABLEAU_BORD],
        },
        loadChildren: () =>
          import(
            './modules/synthese-traitement/synthese-traitement.module'
          ).then((x) => x.SyntheseTraitementModule),
      },
      {
        path: 'configuration',
        canActivate: [canActivateRoute],
        loadChildren: () =>
          import(
            './modules/configuration-chantier/configuration-chantier.module'
          ).then((x) => x.ConfigurationChantierModule),
      },
      {
        path: 'execution',
        canActivate: [canActivateRoute],
        loadChildren: () =>
          import('./modules/execution/execution.module').then(
            (x) => x.ExecutionChantierModule
          ),
      },
    ],
  },

  {
    path: 'account',
    loadChildren: () =>
      import('./modules/login/account.module').then((x) => x.AccountModule),
  },
  { path: 'error', component: AppErrorComponent },
  { path: 'not-authorized', component: AppUnauthorizedComponent },
  { path: 'not-found', component: AppNotfoundComponent },
  { path: '**', component: AppNotfoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Sidebar } from 'primeng/sidebar';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type?: string;
  icontype?: string;
  children?: RouteInfo[];
  right?: boolean;
  isVisible: () => void;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: 'app.sidebar.component.html',
  styleUrls: ['./app.sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit {
  @Input() sidebarExpanded: boolean = true;
  @Output() toggleSidebar = new EventEmitter<void>();

  constructor() {}

  public menuItems?: RouteInfo[];
  ngOnInit() {
    this.menuItems = [
      {
        path: '/synthese',
        title: 'Tableau de bord',
        type: 'sub',
        icontype: 'apps',
        right: true,
        isVisible: () => true,
        children: [
          {
            path: 'traitement',
            title: 'Synthèse traitement',
            isVisible: () => true,
          },
          {
            path: 'comptage',
            title: 'Synthèse comptage',
            isVisible: () => true,
          },
        ],
      },
      {
        path: '/exploration',
        title: 'Exploration de donnnées',
        type: 'sub',
        icontype: 'apps',
        right: true,
        isVisible: () => true,
        children: [
          {
            path: 'indicateur',
            title: 'Par indicateur',
            isVisible: () => true,
          },
          {
            path: 'entite',
            title: 'Par entités',
            isVisible: () => true,
          },
        ],
      },
      {
        path: '/historique',
        title: 'Historique Indicateurs',
        type: 'sub',
        icontype: 'apps',
        right: false,
        isVisible: () => true,
      },
    ];
  }

  onToggleSidebar() {
    this.toggleSidebar.emit();
  }
}

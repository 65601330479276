<div
  style="height: 60vh"
  class="align-content-center"
>
  <div class="col-12">
    <div class="card w-40rem m-auto">
      <div class="flex align-items-center justify-content-center mb-4">
        <img
          class="w-10rem"
          src="assets/img/logo-veolia.png"
        />
      </div>
      <div class="flex align-items-center justify-content-center">
        <i
          class="pi pi-exclamation-circle"
          style="height: 32px; width: 32px; font-size: 32px; margin-right: 10px"
        ></i>

        <h2>404 Ressources introuvable</h2>
      </div>
      <h5 class="w-full mt-3">
        La ressource demandée n'est pas disponible. Merci d'essayer plus tard...
      </h5>
      <div class="flex flex-column align-items-center justify-content-center">
        <a
          mat-flat-button
          color="primary"
          [routerLink]="['/']"
          >Retour à la page d'acceuil</a
        >
      </div>
    </div>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, map } from 'rxjs';
import { UtilisateurModel } from 'src/app/shared/models/habilitationModel';

@Injectable({ providedIn: 'root' })
export class HabilitationApiService {
  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) {}

  //getOne
  public getUserHabiliation(): Observable<UtilisateurModel> {
    return this.http
      .get<any>(`${this.apiUrl}habilitation`)
      .pipe<UtilisateurModel>(map((result) => result.reponse));
  }

  public getSimulatedUserHabiliation(
    email: string,
    codeProjet: string
  ): Observable<UtilisateurModel> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('email', email);
    queryParams = queryParams.append('projet', codeProjet);

    return this.http
      .get<any>(`${this.apiUrl}simulations/habilitation`, {
        params: queryParams,
      })
      .pipe<UtilisateurModel>(map((result) => result.reponse));
  }

  public exitSimulatedUserHabiliation(
    codeProjet: string
  ): Observable<UtilisateurModel> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('projet', codeProjet);
    return this.http
      .get<any>(`${this.apiUrl}simulations/habilitation/exit`, {
        params: queryParams,
      })
      .pipe<UtilisateurModel>(map((result) => result.reponse));
  }
}

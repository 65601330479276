import { Injectable, Inject } from '@angular/core';
import { INGXLoggerConfig, NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    public logger: NGXLogger,
    @Inject('API_URL') private apiUrl: string
  ) {
    let config = this.logger.getConfigSnapshot();
    config.serverLoggingUrl = this.apiUrl + 'logs';
    this.logger.updateConfig(config);
    this.logger.debug('new instance LoggerService');
  }

  public static GetLoggerConfig(): INGXLoggerConfig {
    return {
      level: environment.level,
      serverLogLevel: environment.serverLogLevel,
      timestampFormat: 'dd-MM-yyy HH:mm:ss',
      enableSourceMaps: true,
      disableConsoleLogging: environment.disableConsoleLogging,
    };
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: 'app.navbar.component.html',
  styleUrls: ['./app.navbar.component.scss'],
})
export class AppNavbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  location: Location;

  @ViewChild('app-navbar-cmp') button: any;

  constructor(location: Location, element: ElementRef) {
    this.location = location;
  }

  ngOnInit() {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };
}
